import { I18NPhoneInputProps, I18nMobileInput } from '@ali/merlion-ui';
import debounce from 'lodash/debounce';
import { memo, useCallback } from 'react';

function I18nInput(props: I18NPhoneInputProps) {
  const { onChange, ...rets } = props;

  const handleChange = useCallback(
    debounce((value, ...args) => {
      onChange?.(value, ...args);
    }, 200),
    [],
  );

  return <I18nMobileInput {...rets} onChange={handleChange} />;
}

export default memo(I18nInput);
